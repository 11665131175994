/* istanbul ignore file */
import KatalLogger, { Level } from '@amzn/katal-logger';
import { UserAuthContext } from './components/context/AppContextModels';

export let logger: KatalLogger;

/**
 * @notice Do not change this structure unless communicated with OE team about this change.
 * Once both teams Aligned, then can change the structure of this.
 */
export const configureTheLogger = (userAuthData: UserAuthContext) => {
  const katalLoggerConfig = {
    url: 'https://logger.fintech.device.finance.amazon.dev/v1/log',
    logThreshold: Level.INFO,
    maxLogLineSize: 10000,
    logToConsole: true,
    context: {
      app: 'DasFinSuite-website',
      env: 'prod',
      userAlias: userAuthData.Alias,
      displayName: userAuthData.DisplayName,
      givenName: userAuthData.GivenName,
      email: userAuthData.Email,
      userLDAPGroups: userAuthData.userLDAPGroups,
      isDev: userAuthData.isDev,
      isAdmin: userAuthData.isAdmin,
      isOEReadOnly: userAuthData.isOEReadOnly,
      authStatusMessage: userAuthData.auth_status_message,
      userAuthDataLoadingStatus: userAuthData.userAuthDataLoadingStatus
    }
  };
  logger = new KatalLogger(katalLoggerConfig);
  logger.addErrorListener();
};
